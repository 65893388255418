<template>
  <div class="product">
    <div class="content" id="fullpage">
      <full-page :options="options" ref="fullpage2">
        <template v-for="(item, index) in obj">
          <div
            class="content-item section active"
            :ref="'fullpage' + index"
            :key="item.itemTitle"
          >
            <div class="fp-tableCell">
              <div class="content-item-title" :ref="'top' + index">
                {{ $t(item.itemTitle) }}
              </div>
              <div
                class="content-item-img"
                :ref="'imgB' + index"
                :style="{ height: imgBigHeight }"
              >
                <img
                  v-show="isShow"
                  :ref="'img' + index"
                  :class="['opt', DefaultIndex === index ? 'active' : '']"
                  :style="{ width: imgWidth, height: imgHeight }"
                  :src="item.itemSrcImg"
                  v-lazy="item.itemLazyImg"
                  img-type="pc-phone"
                  alt=""
                />
              </div>
              <div
                @click="toDetails()"
                class="content-item-img-bottom"
                :ref="'bottom' + index"
              >
                {{ $t(item.itemImgBottom) }}
              </div>
              <div class="arrow1" @click="arrownext1()">
                <img :src="item.itemimgDown" />
              </div>
            </div>
          </div>
        </template>
        <div class="section" style="background: #fbfbfb; padding-top: 80px">
          <pc-footer></pc-footer>
        </div>
      </full-page>
    </div>
    <to-top />
  </div>
</template>

<script>
import ToTop from "../../component/totop/toTop.vue";
import pcFooter from "../../component/footer/pcFooter.vue";
import { mapState } from "vuex";
export default {
  name: "product",
  data() {
    return {
      obj: [
        {
          itemTitle: "product.v1.productCpd",
          itemSrcImg: require("@/assets/newQJ/index/image/peijianS.png"),
          itemLazyImg: require("@/assets/newQJ/index/image/peijianS1.png"),
          itemImgBottom: "product.v1.productMove",
          itemimgDown: require("@/assets/newQJ/index/image/down2.png"),
        },
      ],
      status: true,
      DefaultIndex: 0,
      options: {
        //API接口
        //配置背景颜色
        //配置幻灯片切换是否显示箭头，默认true
        controlArrows: false,
        //配置每页内容页面是否垂直居中，默认false
        verticalCentered: true,
        // 配置页面滚动速度,默认700
        scrollingSpeed: 700,
        //配置锚链接，不应该和autoScrolling，scrollBar一起使用
        //anchors: ["/productSearch", "/productSearch"],
        //配置锚点切换时候是否有过度动画，默认true
        animateAnchor: false,
        //锁定配置的锚链接是否显示，默认false
        lokAnchors: true,
        //配置动画由css3还是jquery来执行，默认true
        css3: true,
        easing: "easeInQuart",
        //配置滚动到顶部之后是否从底部循环滚动，默认false
        // loopTop: true,
        //相反从底部循环滚动，默认false
        // loopBottom: true,
        //设置页面是否循环滚动与loopTop，loopBottom不兼容,默认是false
        // continuousVertical: true,
        //幻灯片是否循环滚动，默认true
        loopHorizontal: false,
        //配置是否按照插件的方式来进行滚动，默认true,和锚点不应该一起使用，一般不设置或者保持默认
        autoScrolling: true,
        //滚动的时候是否包含滚动条,默认false,和锚点不应该一起使用，一般不设置或者保持默认
        scrollBar: false,
        //配置页面上下间距，默认0，如果需要设置固定顶部和顶部菜单导航配合使用
        // paddingTop：'300px',
        paddingBottom: 0,
        //配置页面是否有固定的DOM
        // fixedElements: '#header',
        //配置是否支持键盘方向键控制页面切换，默认true
        keyboardScrolling: true,
        //配置是否激活浏览器前进后退功能，如果页面设置了不按照插件的方式来滚动该配置也失效，默认true
        recordHistory: false,
        //配置导航,位置，提示,显示当前位置
        navigation: true,
        navigationPosition: "left",
        // navigationTooltips: ['EC1', 'page2', 'page3', 'page4'],
        showActiveTooltip: true,
        //配置幻灯片是否显示导航,和位置
        slidesNavigation: true,
        slidesNavPosition: "bottom",
        //配置内容超过容器是否显示滚动条，模式true,
        scrollOverflow: false,
        afterLoad: (before, after) => {
          this.DefaultIndex = after.index;
          this.$store.state.index2 = after.index;
        },
      },
      imgWidth: "auto",
      imgHeight: "100%",
      imgBigHeight: "500px",
      isShow: false,
    };
  },
  mounted() {
    window.addEventListener("mousewheel", this.mousewheelHandler, {
      passive: false,
    });
    let timer = null;
    setTimeout(() => {
      this.setItem();
    }, 500);
    window.onresize = () => {
      if (timer) {
        clearTimeout(timer);
        timer = null;
      }
      timer = setTimeout(() => {
        this.setItem();
      }, 500);
    };
  },
  computed: {
    ...mapState(["scroll2"]),
  },
  watch: {
    scroll2() {
      if (this.$store.state.scroll2 != 0) {
        this.$refs.fullpage2.api.moveTo(1);
      }
      this.$store.state.scroll2 = 0;
    },
  },
  methods: {
    arrownext1() {
      this.$refs.fullpage2.api.moveSectionDown();
      this.status = true;
    },
    mousewheelHandler() {
      var e = e || window.event;
      if (e.wheelDelta) {
        if (e.wheelDelta > 0) {
          //当鼠标滚轮向上滚动时
          console.log("鼠标滚轮向上滚动1");
          this.$refs.fullpage2.api.moveSectionUp();
        }
        if (e.wheelDelta < 0) {
          //当鼠标滚轮向下滚动时
          console.log("鼠标滚轮向下滚动");
        }
      } else if (e.detail) {
        if (e.detail < 0) {
          //当鼠标滚轮向上滚动时
          console.log("鼠标滚轮向上滚动2");
        }
        if (e.detail > 0) {
          //当鼠标滚轮向下滚动时
          console.log("鼠标滚轮向下滚动");
        }
      }
    },
    setItem() {
      let height = this.$refs.fullpage0[0].offsetHeight;
      let filterH2 =
        this.$refs.top0[0].offsetHeight + this.$refs.top0[0].offsetTop;
      let filterH3 =
        height -
        this.$refs.bottom0[0].offsetHeight -
        this.$refs.bottom0[0].offsetTop;
      let h = (height - filterH2 - filterH3) * 0.8;
      let ii = this.$refs.imgB0[0];
      let lv = ii.offsetWidth;
      let lv1 = ii.offsetHeight;
      // 图片宽高比
      let bi = 1542 / 1080;
      let box = lv / lv1;
      if (box >= bi) {
        this.imgBigHeight = h + "px";
        this.imgWidth = "auto";
        this.imgHeight = "100%";
      } else {
        this.imgBigHeight = h + "px";
        this.imgWidth = "90%";
        this.imgHeight = "auto";
      }
      this.isShow = true;
      // 判断宽高比
    },
    toDetails() {
      this.$router.push(`/${localStorage.getItem("lans")}/accessories/p1`);
    },
  },
  components: {
    ToTop,
    pcFooter,
  },
  beforeDestroy() {
    window.removeEventListener("mousewheel", this.mousewheelHandler, {
      passive: false,
    });
  },
};
</script>

<style scoped lang="stylus">
.product {
  position: relative;
}

.content-item {
  text-align: center;
  position: relative;
}

.fp-tableCell {
  display: flex;
  align-items: center;
  height: 100%;
}

.content-item-title {
  position: absolute;
  margin-left: 50%;
  transform: translateX(-50%);
  font-size: 36px;
  top: 100px;
  font-weight: bold;
}

.content-item-img-bottom {
  position: absolute;
  margin-left: 50%;
  transform: translateX(-50%);
  bottom: 90px;
  padding: 8px 73px;
  border: 1px solid #030000;
  border-radius: 50px;
  font-size: 19px;
  font-weight: 400;
  color: #3E3A39;
  width: max-content;
  cursor: pointer;
}

.content-item .content-item-img {
  height: 500px;
  margin: 0 auto;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.content-item img {
  height: 100%;
  transform: scale(0.65);
}

.content-item img.active {
  animation: animation 4s ease 1 normal;
}

.arrow1 {
  position: absolute;
  bottom: 25px;
  width: 100%;
  text-align: center;
}

.arrow1 >img {
  width: 40px;
  cursor: pointer;
}

.bgbg {
  background: #fbfbfb;
}

@keyframes animation {
  0% {
    transform: scale(0.65);
  }

  50% {
    transform: scale(1);
  }

  100% {
    transform: scale(0.65);
  }
}

#footer {
  height: calc(100vh);
  padding-top: 80px;
}

@media screen and (max-width: 1440px) {
  .content-item-title {
    font-size: 32px;
  }
}

/* 小于 1280 - 960像素 开始 */
@media screen and (max-width: 1280px) {
  .content-item-title {
    font-size: 30px;
    top: 90px;
  }

  .product {
    top: -50px;
  }
}

/* 小于 1280 - 980 像素 结束 */
/* 750 - 980 像素 结束 */
@media screen and (max-width: 980px) {
  .content-item-title {
    font-size: 28px;
  }

  #footer {
    height: calc(100vh - 100px);
  }
}

/* 750- 540 适配 za */
@media screen and (max-width: 750px) {
  .htmlHeader {
    margin-bottom: 0 !important;
  }

  #footer {
    padding-top: 60px;
  }

  .content-item .arrow1 >img {
    width: 40px;
  }

  .content-item img {
    height: 100%;
    transform: scale(0.8);
  }

  @keyframes animation {
    0% {
      transform: scale(0.8);
    }

    50% {
      transform: scale(1);
    }

    100% {
      transform: scale(0.8);
    }
  }
}

@media screen and (max-width: 540px) {
  .content-item-title {
    font-size: 26px;
    top: 86px;
  }

  .content-item img {
    width: 100%;
    height: auto;
  }

  .content-item-img-bottom {
    bottom: 70px;
    padding: 6px 40px;
    font-size: 16px;
    cursor: pointer;
  }
}
</style>
